<template>
  <div
    :class="[
      'advisor-preview',
      'flow',
      {
        'result-wrapper': result.state && !isNoMatch,
        'no-match': isNoMatch,
        'comparison-wrapper': !!comparison,
      },
    ]"
  >
    <template>
      <div
        v-if="themeName === 'three'"
        class="three-title"
      >
        <div>Help me choose perfect device</div>
      </div>
      <div class="steps">
        <div class="step-wrap">
          <div
            v-for="(item, index) in availableSteps"
            :key="index"
          >
            <custom-step-flow
              v-if="
                (item.id !== 0 && themeName === 'movistargroup') ||
                  (item.id !== 0 && themeName === 'movistar') ||
                  (item.id !== 0 && themeName === 'movistarcl') ||
                  (item.id !== 0 && themeName === 'three') ||
                  (item.id !== 0 && themeName === 'vodafone')
              "
              :key="index"
              :model.sync="steps[index]"
              :values.sync="selected"
              :answers="answers"
              :journey="journey"
              :search-available-options="searchAvailableOptions"
              @setChosenBlock="changeActiveIndex"
              :class="{
                'current-step': index + 1 === availableSteps.length && !result.state,
                'last-step': index + 1 === steps.length,
                'first-child': index === 0,
              }"
            />
            <custom-step-flow
              v-else-if="item.id !== 0"
              :key="index"
              :journey="journey"
              :model.sync="steps[index]"
              :values.sync="selected"
              :answers="answers"
              :search-available-options="searchAvailableOptions"
              @setChosenBlock="changeActiveIndex"
              :class="{
                'current-step': index + 1 === availableSteps.length,
                'last-step': index + 1 === steps.length,
                'first-child': index === 0,
              }"
            />
            <priority-step-flow
              ref="priorityStepFlow"
              v-once
              v-else
              :key="activeIndex"
              :model="priorityStep"
              :values.sync="priorities"
              v-bind="options"
              @click="changeActiveIndex"
              :class="[
                'current-step',
                {
                  'last-step': index + 1 === steps.length,
                },
              ]"
            />
          </div>
        </div>
      </div>
      <div class="result-flow-wrap">
        <div v-show="!hideResults">
          <result-flow
            v-if="result.state && contentType !== 'Article'"
            :journey="journey"
            :model="result.model"
            :devices="result.devices"
            :compared-devices.sync="result.compared"
            :session-id="sessionId"
            v-bind="options"
          />
          <result-article-flow
            v-if="result.state && contentType === 'Article'"
            :journey="journey"
            :model="result.model"
            :products="result.devices"
            :session-id="sessionId"
            v-bind="options"
          />
        </div>
      </div>
      <div
        v-if="comparison"
        :class="['comparison-wrap', { mounted: comparison }]"
      >
        <comparison-flow
          :devices="result.devices"
          :numbers="numbers"
        />
      </div>
    </template>
    <div class="controls-wrap">
      <button
        v-if="activeIndex > 0 && themeName === 'movistarcl'"
        type="button"
        class="btn btn-control btn-prev"
        @click="restart"
      >
        {{ $t('advisor.btn_start-over') }}
      </button>
      <button
        v-if="!result.state"
        type="button"
        class="btn btn-control btn-next"
        :disabled="!canSkip"
        @click="nextStep"
      >
        {{ $t('advisor.btn_next') }}
      </button>
      <button
        v-if="
          result.state && !comparison && !isNoMatch && contentType !== 'Article'
        "
        v-show="!hideResults"
        type="button"
        class="btn btn-control btn-compare"
        @click="toggleComparison"
      >
        {{ $t('advisor.btn_compare') }}
      </button>
    </div>
  </div>
</template>

<script>
import { createGuid } from '~/utils'
import CustomStepFlow from '~/components/sp-advisor/advisor-flow/CustomStepFlow.vue'
import PriorityStepFlow from '~/components/sp-advisor/advisor-flow/PriorityStepFlow.vue'
import ResultFlow from '~/components/sp-advisor/advisor-flow/ResultFlow.vue'
import ComparisonFlow from '~/components/sp-advisor/advisor-flow/ComparisonFlow.vue'
import ResultArticleFlow from '~/components/sp-advisor/ResultArticle.vue'

export default {
  components: { CustomStepFlow, PriorityStepFlow, ResultFlow, ComparisonFlow, ResultArticleFlow },
  props: {
    journey: { type: Object, default: () => ({}) },
    id: { type: Number, required: true },
    stages: { type: Array, default: () => [] },
    options: { type: Object, default: () => ({}) },
    numbers: { type: Number, default: 3 },
    language: { type: Number, default: 1 },
    hideResults: { type: Boolean, default: false },
    contentType: { type: String, default: '' },
  },
  data () {
    const copied = JSON.parse(JSON.stringify(this.stages))
    const themeName = typeof CLIENT !== 'undefined' ? CLIENT.theme : 'generic'
    const sessionId = createGuid()
    return {
      initialSteps: copied.filter((x) => x.type !== 'result'),
      themeName: themeName || 'generic',
      result: {
        state: false,
        model: copied.find((x) => x.type === 'result'),
        devices: [],
        compared: this.options.compared || [],
      },

      comparison: false,

      activeIndex: -1,
      answers: [],
      sessionId,
      searchAvailableOptions: {}
    }
  },
  mounted () {
    this.$root.$on('app::advisor::addPriorityStep', this.addPriorityStep)
    this.$root.$on('app::advisor::removePriorityStep', this.removePriorityStep)

    this.activeIndex = 0
    this.search()
    this.callSamplingHandler('advisor')
  },
  computed: {
    priorityStep () {
      return this.steps.find((item) => item.id === 0)
    },
    availableSteps () {
      const steps = [...this.steps]
      return steps.splice(0, this.activeIndex + 1)
    },
    isNoMatch () {
      return this.result.state && this.result.devices.length < 1
    },
    steps () {
      return this.initialSteps
    },
    activeStep: {
      get () {
        if (typeof this.steps[this.activeIndex] === 'undefined') return {}
        return this.steps[this.activeIndex]
      },
      set (value) {
        this.initialSteps.splice(this.activeIndex, 1, value)
      },
    },
    selected: {
      get (item) {
        const type = this.activeStep.type
        const existedField = this.answers.findIndex((item) => item.id === this.activeStep.id)
        // if the model is already existed then manage its index. In other case get the values in the end of the array
        const index = existedField > -1 ? existedField : this.activeIndex
        const result = this.answers[index]
        return (result && result[type]) || []
      },
      set (values) {
        if (this.$refs.priorityStepFlow) {
          this.$refs.priorityStepFlow[0]?.$el.classList.remove('current-step')
        }
        if (this.result.state) {
          const copied = JSON.parse(JSON.stringify(this.stages))
          this.comparison = false
          this.result = {
            state: false,
            model: copied.find((x) => x.type === 'result'),
            devices: [],
            compared: this.options.compared || [],
          }
        }
        const type = this.activeStep.type
        const model = {}
        model.id = this.activeStep.id
        model[type] = values
        const existedField = this.answers.findIndex((item) => item.id === model.id)
        // if the model is already existed then manage its index. In other case add a new model in the end of the array
        const index = existedField > -1 ? existedField : this.activeIndex
        this.answers.splice(index, 1, model)
      },
    },
    priorities: {
      get () {
        const prioritiesAnswers = this.answers.find((item) => item.priorities) || {}
        const result = this.answers[this.activeIndex]
        return (result && prioritiesAnswers.priorities) || []
      },
      set (model) {
        const prioritiesAnswersIndex = this.answers.findIndex((item) => item.priorities)
        this.answers.splice(prioritiesAnswersIndex, 1, { priorities: model })
        if (this.$refs.priorityStepFlow) {
          this.$refs.priorityStepFlow[0]?.$el.classList.add('current-step')
        }
        if (prioritiesAnswersIndex < this.activeIndex) {
          this.activeIndex = prioritiesAnswersIndex
          for (let i = this.activeIndex; i < this.answers.length; i++) {
            if (!this.answers[i].priorities) {
              this.answers[i] = {}
            }
          }
        }
        this.comparison = false
        if (this.result.state) {
          const copied = JSON.parse(JSON.stringify(this.stages))
          this.result = {
            state: false,
            model: copied.find((x) => x.type === 'result'),
            devices: [],
            compared: this.options.compared || [],
          }
        }
      },
    },
    hasAnswers () {
      return this.answers.filter((x) => Object.keys(x).length > 0).length > 0
    },
    canSkip () {
      if (this.themeName === 'movistarcl') return true

      const { canSkip, selectAll } = this.activeStep
      const hasValues =
        (this.selected && this.selected.length) ||
        (selectAll && selectAll.state)
      return typeof canSkip === 'undefined' || canSkip ? true : hasValues
    }
  },
  methods: {
    changeActiveIndex (payload) {
      this.$nextTick(() => {
        const index = this.steps.findIndex((item) => item.id === payload.id)
        if (index < this.activeIndex) {
          this.activeIndex = index
        }

        // reset all answers below except current and earlier
        for (let i = this.activeIndex; i < this.answers.length; i++) {
          if (!this.answers[i].priorities && !this.answers[i][payload.type]) {
            this.answers[i] = {}
          }
          if (this.steps[i].selectAll && this.steps[i].selectAll.state) {
            this.steps[i].selectAll.state = false
          }
        }
      })
    },
    nextStep () {
      if (this.$refs.priorityStepFlow) {
        this.$refs.priorityStepFlow[0]?.$el.classList.remove('current-step')
      }

      this.toggleLoaderOverlay(true)
      this.search()
        .then(() => {
          this.activeIndex += 1

          if (typeof this.steps[this.activeIndex] === 'undefined') {
            this.result.state = true
          }
        })
        .finally(() => {
          this.toggleLoaderOverlay(false)
        })
    },
    prevStep () {
      if (this.result.state) {
        if (this.comparison) {
          this.comparison = false
          return
        }

        this.result.state = false
      }

      if (typeof this.steps[this.activeIndex - 1] !== 'undefined') {
        this.activeIndex -= 1
      }
    },
    restart () {
      this.activeIndex = 0
      this.answers = Array(50).fill({})
      this.result.state = false
      this.comparison = false
      this.result.devices = []
      this.search()
    },
    toggleComparison () {
      this.comparison = !this.comparison
    },
    addPriorityStep (model) {
      const index = this.activeIndex + 1
      if (this.initialSteps[index] && this.initialSteps[index].id === 0) {
        this.initialSteps.splice(index, 1)
      }
      const isPriorityExist = this.initialSteps.find((item) => item.type === 'priority')
      if (!isPriorityExist && this.initialSteps[this.activeIndex].interrate.enabled) {
        const insert = (arr, index, ...newItems) => [...arr.slice(0, index), ...newItems, ...arr.slice(index)]
        this.initialSteps = insert(this.initialSteps, index, model)
        this.answers.splice(index, 1, {
          priorities: [],
        })
      }
    },
    removePriorityStep () {
      const index = this.activeIndex + 1
      if (this.initialSteps[index] && this.initialSteps[index].id === 0) {
        this.initialSteps.splice(index, 1)

        // Remove answers by current step
        this.answers.splice(index, 1, {})
      }
    },
    async search () {
      const filtered = this.answers.filter((x) => Object.keys(x).length > 0)

      const getValues = (array, key) => {
        return [...new Set(array.map((item) => item[key]).flat())].filter((x) => typeof x !== 'undefined')
      }

      const infinity = getValues(filtered, 'infinity')
      const priorities = getValues(filtered, 'priorities')

      const interval = filtered.filter((x) => Object.keys(x).findIndex((key) => key === 'interval') > -1)

      const getIntervalId = (stageId) => {
        const stage = this.stages.find((x) => x.id === stageId)
        if (stage && stage.interval) {
          return stage.interval.id
        } else {
          return null
        }
      }

      const requestModel = {
        contentType: this.contentType,
        languageId: parseInt(this.language),
        channelId: 3, // Presale = 3
        advisorId: this.id,
        take: this.numbers || 3, // devices count
        specificationSlugs: getValues(filtered, 'specifications'),
        brands: getValues(filtered, 'brands'),
        advisorParameters: infinity.map((x) => ({
          id: x,
          weight: (priorities.find((e) => e.id === x) || {}).rating || 3,
        })),
        intervalParameters: interval
          .map((x) =>
            x.interval.map((item) => ({
              id: getIntervalId(x.id),
              value: item,
            }))
          )
          .flat(),
      }

      const isLast = typeof this.steps[this.activeIndex + 1] === 'undefined'
      if (isLast) {
        const response = await this.$api.getAdvisorSearchProducts(requestModel, this.contentType)
        if (response && response.data) {
          this.result.devices = response.data
          this.$root.$emit('app::advisor::recommendations', this.result.devices)
        }
      }

      const response = await this.$api.getAdvisorSearchAvailableOptions(requestModel)
      if (response && response.data) {
        this.searchAvailableOptions = response.data || {}
      }
    },
    toggleLoaderOverlay (state) {
      this.$root.$emit('app::loader-overlay::toggle', state)
    },
    renewSession () {
      this.sessionId = createGuid()
    },
    callSamplingHandler (handler, ...params) {
      if (!this.journey) return

      const { id, contentType, language: languageId } = this.journey
      const baseParams = [id, contentType, languageId, this.sessionId]
      this.$sampling.spSample(handler, ...[...baseParams, ...params])
    },
    getSelectedOptionsNames (stage, index) {
      const optionsNames = []

      const selected = this.answers.find(x => x.id === stage.id)
      if (selected) {
        switch (stage.type) {
          case 'infinity':
            Array.from(selected.infinity).forEach(value => {
              const option = Array.from(stage.infinityParams).find(option => option.value === value)
              if (option) optionsNames.push(option.text)
            })
            break
          case 'specifications':
            Array.from(selected.specifications).forEach(value => {
              const option = Array.from(stage.specificationsParams).find(option => option.value === value)
              if (option) optionsNames.push(option.text)
            })
            break
          case 'brands':
            Array.from(selected.brands).forEach(value => {
              const option = Array.from(stage.brandsParams).find(option => option.value === value)
              if (option) optionsNames.push(option.text)
            })
            break
          case 'interval':
            Array.from(selected.interval).forEach(value => {
              const option = Array.from(stage.intervalParams).find(option => option.value === value)
              if (option) optionsNames.push(option.text)
            })
            break
        }
      }

      if (stage.selectAll && stage.selectAll.state) {
        optionsNames.push(stage.selectAll.text)
      }

      return optionsNames
    }
  },
  watch: {
    activeIndex (curr, prev) {
      if (curr === -1) return

      const stepsCount = this.steps.length
      const lastIndex = stepsCount // result step index

      if (prev > curr) {
        /** when we back to the previous step, we need to re-new session and sample all data to the 'curr' index like it's a new open */
        this.renewSession()

        this.callSamplingHandler('advisor')

        Array.from(this.steps).slice(0, curr).forEach((stage, index) => {
          const stageId = stage.id

          if (!stageId) return

          this.callSamplingHandler('advisorStage', stageId)

          const optionsNames = this.getSelectedOptionsNames(stage, index)
          if (optionsNames.length) {
            this.callSamplingHandler('advisorStage', stageId, optionsNames)
          }
        })
      }

      if (curr === lastIndex) {
        const stage = this.result.model
        const stageId = stage.id
        const products = Array.from(this.result.devices)
        const productsIds = products.slice(0, this.numbers).map(p => p.id)

        this.callSamplingHandler('advisorStageFinal', stageId, productsIds)
      } else {
        const stage = this.steps[curr]
        const stageId = stage.id

        if (stageId) {
          this.callSamplingHandler('advisorStage', stageId)
        }
      }

      if (prev !== -1 && prev < curr) {
        const stage = this.steps[prev]
        const stageId = stage.id

        if (!stageId) return

        const optionsNames = this.getSelectedOptionsNames(stage, prev)
        if (optionsNames.length) {
          this.callSamplingHandler('advisorStage', stageId, optionsNames)
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .advisor-preview {
    .steps {
      min-height: 300px;
    }

    .progress-wrap {
      margin: 16px 0;
      display: flex;
      justify-content: center;

      .progress {
        display: flex;
      }

      .progress-point {
        margin: 0 4px;
        width: 8px;
        height: 8px;
        background-color: $dark;

        &.active {
          transform: scale(1.25);
        }
      }
    }

    .controls-wrap {
      margin: 16px 0;
      display: flex;
      gap: 10px;

      .btn-control {
        border: 1px solid $dark;
        border-radius: 0;
        //&.btn-compare {
        //  display: none;
        //}
      }
    }
  }
}
</style>
